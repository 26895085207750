import './App.css';
import Main from './Pages/Main';
import Mint from './Pages/Mint';
import Admin from './Pages/Admin';
import WhiteList from './Pages/WhiteList';
import WhitePaper from './Pages/WhitePaper';
import Loader from './Pages/Loader';
//ReactRouter
import { BrowserRouter as Router, Routes ,Route ,useLocation} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
//AnimationOnScroll
import AOS from 'aos'
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";


import { Web3Modal } from "@web3modal/react";

import { useAccount, configureChains, createClient, WagmiConfig,  } from "wagmi";

import { arbitrum, mainnet, polygon, goerli } from "wagmi/chains";

import { useWeb3ModalTheme } from "@web3modal/react";




AOS.init({
  offset: 200,
  duration: 600,
  easing: 'ease-in-sine',
  delay: 100,
});


const chains = [arbitrum, mainnet, polygon, goerli];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "84cc8766582b9fdf77a0a4164b0156a8" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: "84cc8766582b9fdf77a0a4164b0156a8",
    version: "2", // or "2"
    appName: "web3Modal",
    chains,
  }),
  provider,
});


// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);






function App() {
  const location = useLocation();
  return (
          <div  className="App">
                  <WagmiConfig client={wagmiClient}>

           <AnimatePresence exitBeforeEnter>

<Mint></Mint>

           </AnimatePresence>
           </WagmiConfig>

           <Web3Modal
        projectId="84cc8766582b9fdf77a0a4164b0156a8"
        ethereumClient={ethereumClient}
      />

          </div>
  );
}

export default App;
