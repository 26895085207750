import react, {useState,useEffect,createContext} from 'react'
import { ethers } from "ethers";
import { contractABI, contractAdress } from "../utils/constants";
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
//Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//FireBase
import {db} from '../Firebase';
import {
  collection,
  getDocs,
} from "firebase/firestore";
import { Alchemy } from 'alchemy-sdk';

import { useAccount, configureChains, createClient, WagmiConfig,  } from "wagmi";
import WLaddresses from '../Components/WhitelistChecker/WhitelistAddresses'
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");





export const StateContext = createContext();
export const StateProvider= ({ children }) => {



  const web3 = createAlchemyWeb3("https://eth-mainnet.g.alchemy.com/v2/KmWCzn6OjqMyWwGvskLQqq4YWaeO34-H"); 
  
  
  const Base_Alchemy = new web3.eth.Contract(
   contractABI,
   contractAdress
 );




  const account = useAccount()
  const isConnected = account?.isConnected;
  const address = account?.address;



  //account.isConnected
    //Whitelisted
     const [Whitelisted, setWhiteListed] = useState ([]);
    //States
     const [loading, setLoading] = useState(false);
     const [Pageloading, setPageLoading] = useState(false);
     const [currentAccount, setCurrentAccount]= useState();

     const [currentNetwork, setCurrentNetwork]= useState();
     const [currentAccountBalance, setCurrentAccountBalance] = useState();
     const [ContractInfo, setContractInfo] = useState(
       {
           PriceSale    : '-',
           PricePresale : '-',
           SaleStep     : '-',
           MaxSupply    : '-',
           TotalSupply  : '-',
           MintsPublic: 0,
           MintsWhitelist: 0,
           MaxMintsWhitelist : 0,
           MaxMintsPublic : 0,

       }
     )


 






     useEffect(()=>{
      /*  HandleWalletChange();
        HandleNetworkChange();
        updateWallet();
        */

        if (isConnected){
UpdateInfo()


        }
        else{
          GetInfo();


        }
      },[])









     //************||WALLET||**************/
      //HandleConnectWallet
      const HandleConnectWallet = async() =>{
        if(window.ethereum)
        {
          if(!isConnected)
          {
             try{
              
          /*        const accounts_ = await window.ethereum.request({ method: "eth_requestAccounts" });

                  const chainId_  = await window.ethereum.request({ method: 'eth_chainId'});


            

                  setCurrentAccount(accounts_[0]);
                  setCurrentNetwork(chainId_);
                  GetInfo();
                  */

                //  setCurrentAccount();

                toast.info('Please connect wallet')

                  return 1;
             }
             catch(err)
             {
                  toast.error(err.message);
             }
          }
          else{
              toast.info('you are already connected! click on metamask if you would like to change the account.')
          }
          }
          else{
               toast.info('Please make sure you have metamask installed and connected !');
          }
        }
    //GetCurrentWallet
    const GetCurrentWallet = async() => {
      if (window.ethereum)
      {
        const CurrentAccounts_ = await window.ethereum.request({
          method: "eth_accounts",
        });
        return CurrentAccounts_[0];  
      }
    }
      //GetCurrentNetwork
      const GetCurrentNetwork_ = async() => {
        if (window.ethereum)
        {
          const CurrentNetwork_ = await window.ethereum.request({
            method: 'eth_chainId'
          });
          return CurrentNetwork_;  
        }
      }
    //updateWallet
    const updateWallet = async() =>{
      const res   = await GetCurrentWallet();
      const Chain = await GetCurrentNetwork_(); 
      setCurrentAccount(res);
      setCurrentNetwork(Chain);
    }
    //HandleWalletChange
    const HandleWalletChange = async ()=>{
     try{
      window.ethereum.on('accountsChanged', async(accounts) => {
        setCurrentAccount(await GetCurrentWallet());
      })
     }
     catch(err)
     {
       console.log(err);
     }
    }

    //HandleNetworkChange 
    const HandleNetworkChange = async ()=>{
      try{
        window.ethereum.on('chainChanged', (chainId) => {
         window.location.reload();
        });
      }
      catch(err)
      {
        console.log(err);
      }
     }
    /***********||END WALLET||************/

    //************||Mint||*************/
    const Mint_ = async(counter) =>{

           if(isConnected)
           {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const Base = new ethers.Contract(contractAdress, contractABI, signer);            

            let Ammount = counter;
            let overrides = {
              value: ContractInfo.PriceSale,
              from: address,
            }
            try{
                const Transaction = await Base.PublicMint(Ammount, overrides);
                setLoading(true);
                await Transaction.wait();
                UpdateInfo();
                setLoading(false);
                toast.success('Congrats! You Minted your Llama NFTs');
            }
            catch(error)
            {
               if(typeof error === 'object')
               {
                   // Support any type of error from the Web3 Provider...
                      if (error?.error?.message !== undefined) {
                        toast.info(error.error.message);
                      } else if (error?.data?.message !== undefined) {
                        toast.info(error.data.message);
                      } else if (error?.message !== undefined) {
                        toast.info(error.message);
                      }
               }
            }
           }
           else{
               toast.info('Connect your wallet First!');
           }
   
    }
   /*************||EndMint||***************/
  //  const getWhiteListed = async () => {
  //   const res = await getDocs(CollectionRef);
  //   const WhiteListed = [];
  //   res.forEach((doc) => {
  //     WhiteListed.push(doc.data().address);
  //   });
  //   return WhiteListed;
  //   }


  const GetProof = async(address) =>{
    const buf2hex = (x) => "0x" + x.toString("hex");
    const whitelistAddresses = WLaddresses;
    const leaves = whitelistAddresses.map((x) => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    //const root = tree.getHexRoot();

    const Addr = (address);
    const leaf = keccak256(Addr);
    const proof = tree.getProof(leaf).map((x => buf2hex(x.data)));
    



    return proof;
   }


  //************||PreSaleMint||*************/
     const PreSaleMint = async(counter) =>{
      if(typeof window.ethereum !=='undefined')
      {
       const provider = new ethers.providers.Web3Provider(window.ethereum);
       const signer = provider.getSigner();
       const Base = new ethers.Contract(contractAdress, contractABI, signer);
       const proof = await GetProof(address);




       let Ammount = counter;

       let overrides = {
         value: ContractInfo.PricePresale,
         from:  address
       }
       try{
           const Transaction = await Base.WhitelistMint(Ammount, proof,overrides);
           setLoading(true);
           await Transaction.wait();
           toast.success('Congrats! You Minted your Llama NFTs');
           setLoading(false);
           UpdateInfo();
       }
       catch(error)
       {
        if(typeof error === 'object')
        {
            // Support any type of error from the Web3 Provider...
               if (error?.error?.message !== undefined) {
                 toast.info(error.error.message);
               } else if (error?.data?.message !== undefined) {
                 toast.info(error.data.message);
               } else if (error?.message !== undefined) {
                 toast.info(error.message);
               }

               console.log(error.message)
        }
       }
      }
      else{
          toast.info('Please make sure you have metamask installed and connected !');
      }
   }
   /*************||EndPreSaleMint||************   


   /************||GetInfo||***************/
   const GetInfo = async() =>{
    
      setPageLoading(true);



/*

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const Base = new ethers.Contract(contractAdress, contractABI, signer);
*/





        let Price_        = await Base_Alchemy.methods.PRICE_PUBLIC().call();
      
      
        let PricePresale_ = Number( await Base_Alchemy.methods.PRICE_WHITELIST().call());
        let SaleStep_     = Number( await Base_Alchemy.methods.llamaStages().call())
        let MaxSupply_    = Number(await Base_Alchemy.methods.MAX_SUPPLY().call());
        let TotalSupply_  = Number(await Base_Alchemy.methods.totalSupply().call());
        let MaxMintsPublic_  = Number(await Base_Alchemy.methods.MAX_PUBLIC_MINTS_WALLET().call());
        let MaxMintsWhitelist_  = Number(await Base_Alchemy.methods.MAX_WHITELIST_MINTS_WALLET().call());

      setContractInfo(
         {
          PriceSale    : Price_,
          PricePresale : PricePresale_,
          SaleStep     : SaleStep_,
          MaxSupply    : MaxSupply_,
          TotalSupply  : TotalSupply_,
          MaxMintsPublic : MaxMintsPublic_,
          MaxMintsWhitelist : MaxMintsWhitelist_
         }
      )
      setPageLoading(false);
   }

   const UpdateInfo = async()=>{
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Base = new ethers.Contract(contractAdress, contractABI, signer);

    const proof = await GetProof(address);
    let Price_        = await Base.PRICE_PUBLIC();
    let PricePresale_ = await Base.PRICE_WHITELIST();
    let SaleStep_     = await Base.llamaStages();
    let MaxSupply_    = await Base.MAX_SUPPLY();
    let TotalSupply_  = await Base.totalSupply();
    let MintsWhitelist_  = await Base.WhitelistMintClaimed(address);
    let MintsPublic_  = await Base.PublicMintClaimed(address);
    let MaxMintsPublic_  = await Base.MAX_PUBLIC_MINTS_WALLET();
    let MaxMintsWhitelist_  = await Base.MAX_WHITELIST_MINTS_WALLET();
    let isWhitelisted_  = await Base.isWhiteListed(address, proof);

  setContractInfo(
     {
      PriceSale    : Price_,
      PricePresale : PricePresale_,
      SaleStep     : SaleStep_,
      MaxSupply    : MaxSupply_,
      TotalSupply  : TotalSupply_,
      MintsPublic : MintsPublic_,
      MintsWhitelist : MintsWhitelist_,
      MaxMintsPublic : MaxMintsPublic_,
      MaxMintsWhitelist : MaxMintsWhitelist_,
      isWhitelisted : isWhitelisted_
    }
  )
   }

   /**********||EndGetInfo||***************/
    return(
        <StateContext.Provider
        value={{
            HandleConnectWallet,
            Mint_,
            PreSaleMint,
            setWhiteListed,
            Whitelisted,
            ContractInfo,
            address,
            currentAccountBalance,
            currentNetwork,
            loading,
            Pageloading,
            isConnected
        }}
        >
            {children}
        </StateContext.Provider>
    );
}