import React from 'react'
import styled from 'styled-components'
import Countdown from 'react-countdown';
import {Link} from 'react-router-dom'



  

  // Renderer callback with condition
 const Counter = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>completed</p>;
    } else {
      // Render a countdown
      return <StyledCounter>
        <div className='Holder'>
          <div className='CountHolder'>
          <h2>{days}</h2>
          </div>
          <h2 className='date_'>Day</h2>
        </div>
  
        <div className='Holder'>
          <div className='CountHolder'>
          <h2>{hours}</h2>
          </div>
          <h2 className='date_'>Hours</h2>
        </div>
  
        <div className='Holder'>
          <div className='CountHolder'>
          <h2>{minutes}</h2>
          </div>
          <h2 className='date_'>Minutes</h2>
        </div>
  
        <div className='Holder'>
          <div className='CountHolder'>
          <h2>{seconds}</h2>
          </div>
          <h2 className='date_'>Seconds</h2>
        </div>
        </StyledCounter>;
    }
  };




const StyledCounter = styled.div`
color :white;
display :flex;
flex-direction :row;


.Holder{
  display: flex;
  flex-direction :column;
  justify-content: space-between;
  align-items : center;
  width :100%;
  .date_{
    font-family: 'Poppins', sans-serif;
    text-transform : uppercase;
    font-size : 15px;
    margin-top :4px;
    color: white;
    @media (max-width: 768px) {
      font-size : 10px;
    }
  }

  .CountHolder{
  width :70px;
  height:70px;
  background : transparent;
  border : 1px solid var(--MainColor);
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content : center;
  align-items :center;
  margin-top : 20px;

  @media (max-width: 768px) {
    width :50px;
  height:50px;
    }

  h2{
    font-family: 'Poppins', sans-serif;
    font-size : 30px;
  }
  }
}
`


export default Counter;