import { useState, useContext } from "react";
import useSWR from "swr";
import WLaddresses from "./WhitelistAddresses";
// Setup: npm install alchemy-sdk
import { Alchemy, Network } from "alchemy-sdk";
//Notifications Component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components'

const useenswallet = async (ethaddr) =>{
let addr;

    if (ethaddr.endsWith(".eth")){


    const config = {
        apiKey: "KmWCzn6OjqMyWwGvskLQqq4YWaeO34-H",
        network: Network.ETH_MAINNET,
      };
      const alchemy = new Alchemy(config);

      let addr_ = await alchemy.core.resolveName(ethaddr);
      if(addr_){
        addr = addr_.toLowerCase()
      }
      else{
        addr = ethaddr.toLowerCase();
      }


    }
    else{
        addr = ethaddr.toLowerCase();
    }

     return addr;
}





export default function WhitelistChecker() {





  const fetcher = (url) => fetch(url).then((res) => res.json());
 /* const { data, error } = useSWR(
    "./Adresses.json",
    fetcher
  );*/
  const data = WLaddresses;


  console.info(data)
  const WLS = data;

  const [Message, setMSG] = useState(
   ""
  );

  const HandleCheck =async (addr) => {
    let loweracc = WLS.map((acc) => {
      return acc.toLowerCase();
    });
    let useradd;


    useradd = await useenswallet (addr.toLowerCase());


    if ( (String(addr).length == 42 && String(addr).toLowerCase().startsWith("0x") ) || (String(addr).toLowerCase().endsWith('.eth') && String(addr).length < 42)) {



      if (loweracc.includes(useradd) ) {
        setMSG("✅ Whitelisted")
        toast.success('Congrats! ETH Address is Whitelisted');

  
      } else {
        /*setMSG(
          <p className="sm:text-xl text-lg text-red-500">Not Whitelisted</p>
        );*/
        setMSG("❌ Not Whitelisted")
        toast.error('ETH Address Not Whitelisted');   
  
      }






    }
else{



  
}


    /*
    else if (String(addr).endsWith('.eth')){
        let xaddr=alchemy.core.resolveName(String(addr))



    }
    */


  };

  return (
    <StyledWL>
                  <p className='Note'>Are you Whitelisted? Check it here</p>




          <input
            className=" inputwl w-full text-black p-3 focus:outline-2 focus:outline-slate-400 caret-slate-500 rounded-md"
            type={"text"}
            placeholder={"Paste Your Address or ENS to check"}
            onChange={(e) => {
              e.preventDefault();
              HandleCheck(e.target.value);
            }}
          />

<p className='Note2'>{Message}</p>

     
      </StyledWL>
  );
}




const StyledWL = styled.div`
   border-bottom:0;
   border-left:0;
   border-right:0;
   padding : 0px 6%;
   display: flex;
   flex-direction : row;
   align-items : center;
   justify-content : space-between;
   font-family: 'Nunito Sans', sans-serif;
   width  :100%;
   font-size: 18px;
   margin-bottom: 5px;
  .inputwl{
    display : flex;
    width: 70%;
    max-width : 800px;
    height: 30px;
    border-radius:2px;
    text-align: center;
  }

  .Note{
    color : #ecdfbc;
    font-size : 1.2rem;
    font-family: 'Nunito Sans', sans-serif;
    left: 50%;


    @media (max-width : 768px){
      bottom : 0;
      left: 0;
      transform : unset;
      font-size: 18px; 
           text-align : center;
    }
  }

  .Note2{
    color : #ecdfbc;
    font-size : 0.8rem;
    font-family: 'Nunito Sans', sans-serif;
    left: 50%;


    @media (max-width : 768px){
      bottom : 0;
      left: 0;
      transform : unset;
      font-size: 15px; 
           text-align : center;
    }
  }



   .Social{
       display : flex;
       align-items : center;
       justify-content : center;
   }
   .SocialLogo{
       color :white;
       transition: 0.3s ease-in-out;
   }
   .SocialLogo:first-child{
       margin-right : 10px;
   }
   .twt:hover{
    color :#2aa9e0;
   }
   .dsc:hover{
    color : rgba(86,98,246);
   }
   .ins:hover{
    color : #bc2a8d
   }
   
   p{
       color : white;
   }
   .author{
       color : white;
   }
   .hk{
       width : 18px;
   }
   .copyright{
       display : flex;
       flex-direction : row;
       justify-content : right;
       align-items  :center;
       width :33.33%;
   }
   .copyright p{
       margin-right  :10px;
   }
   @media (max-width: 768px) {
    flex-direction : column;
    .copyright{
        width :100%;
        justify-content : center;
    }
    .copyright p{
        font-size: 12px;
    }
    .Social{
        margin-bottom : 10px;
   }
    }
`